import { TextField, Grid, Paper, Box, IconButton, Chip } from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  setText,
  remove,
} from './formSlice';
import { Close } from '@mui/icons-material';
import { StatementColorPicker } from './ColorPicker';
import { BoldButton, FontSelector } from './FontSettings';



export const Statement = ({number, isAdvanced}:{number:number, isAdvanced:boolean}) => {
  return (isAdvanced?<AdvancedStatement number={number}/>:<BasicStatement number={number}/>);
}

const BasicStatement = ({number=0}) =>{
  const dispatch = useAppDispatch();

  return (<Grid item key={number} xs={12} sm={6}>
    <Paper elevation={3}>
      <Box paddingX={2} paddingBottom={2} paddingTop={1}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <NumberChip number={number+1}/>
          </Grid>
          <Grid item>
            <IconButton onClick={(e)=>dispatch(remove(number))} size='small'>
              <Close fontSize='small'/>
            </IconButton>
          </Grid>
        </Grid>
        <StatementTextField number={number}/>
      </Box>
    </Paper>
</Grid>);
}


const AdvancedStatement = ({number=0}) =>{
  const dispatch = useAppDispatch();

  return (<Grid item key={number} xs={12} sm={6}>
    <Paper elevation={3}>
      <Box paddingX={2} paddingTop={1} paddingBottom={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              
              <Grid item>
                <NumberChip number={number+1}/>
              </Grid>

              <Grid item>
                <Box paddingX={1}>
                  <FontSelector number={number}/>
                </Box>
              </Grid>

              <Grid item>  
                <StatementColorPicker number={number}/>
              </Grid>

              <Grid item>
                <BoldButton number={number} />
              </Grid>

            </Grid>
          </Grid>

          <Grid item>
            <IconButton onClick={(e)=>dispatch(remove(number))} size='small'>
              <Close fontSize='small'/>
            </IconButton>
          </Grid>
          
        </Grid>
        <StatementTextField number={number}/>
      </Box>
    </Paper>
</Grid>);
}

const StatementTextField = ({number=0}) => {
  const text = useAppSelector((state)=>state.form.statements[number].text);
  const dispatch = useAppDispatch();
  const showCaution = text.split('\n').some((t)=>t.length>20);
  const caution = '一行あたりの文字数が多いと適切に配置できない場合があります。改行することをおすすめします。';

  return (
  <TextField color='primary' 
    placeholder='発言を入力してください' 
    variant='standard'
    value={text} helperText={showCaution?caution:''}
    onChange={(e) => dispatch(setText({number,value:e.target.value}))}
    multiline fullWidth />
  );
}

const NumberChip = ({number}:{number:number}) => {
  return (
    <Chip variant="outlined" color="primary" size="small" label={number}/>
   );
}