export class XorShift128 {
	private w: number;
	private x: number = 123456789;
	private y: number = 362436069;
	private z: number = 521288629;

	constructor(seed = 88675123) {
		this.w = seed;
	}

	private next = () => {
		const t: number = this.x ^ ((this.x << 11) >>> 0);
		this.x = this.y;
		this.y = this.z;
		this.z = this.w;

		return (this.w = this.w ^ (this.w >>> 19) ^ (t ^ (t >>> 8))) >>> 0;
	};

	rand = () => this.next() / 4294967296;

	randInt = (max: number) => Math.abs(this.next()) % max;

	randIntBetween = (min: number, max: number) =>
		min + (Math.abs(this.next()) % (max - min));

	shuffle = <T>(array: T[]) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = this.randInt(i + 1);
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	};
}
