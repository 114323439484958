import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";
import { FontList, FontWeights } from "../fontList";
import { Color, fromRGBColor } from "../color";

interface StatementState {
	text: string;
	font: FontList;
	weight: number;
	color: Color | null;
}

const defaultStatement: StatementState = {
	text: "",
	font: FontList.DEFAULT,
	weight: 400,
	color: null,
};

export interface FormState {
	statements: StatementState[];
	color: Color;
	backgroundColor: Color;
	font: FontList;
	randomWeights: boolean;
	spacing: number;
	width: number;
	height: number;
}

export const initialState: FormState = {
	statements: [defaultStatement, defaultStatement, defaultStatement],
	color: fromRGBColor({ r: 0, g: 0, b: 0, a: 1 }),
	backgroundColor: fromRGBColor({ r: 255, g: 255, b: 255, a: 1 }),
	font: FontList.NOTO_SERIF,
	randomWeights: true,
	spacing: 2,
	width: 1280,
	height: 720,
};

interface TextPayload {
	number: number;
	value: string;
}

interface ColorPayload {
	number: number;
	color: Color;
}

interface NumberPayload {
	number: number;
	value: number;
}

interface FontPayload {
	number: number;
	font: FontList;
}

export const FormSlice = createSlice({
	name: "form",
	initialState: loadState(initialState, 12 * 60 * 60 * 1000),
	reducers: {
		setWidth: (state, action: PayloadAction<number>) => {
			state.width = Math.max(0, action.payload);
		},
		setHeight: (state, action: PayloadAction<number>) => {
			state.height = Math.max(0, action.payload);
		},
		setSpacing: (state, action: PayloadAction<number>) => {
			state.spacing = Math.max(0, action.payload);
		},
		setDefaultColor: (state, action: PayloadAction<Color>) => {
			state.color = action.payload;
		},
		setDefaultBackgroundColor: (state, action: PayloadAction<Color>) => {
			state.backgroundColor = action.payload;
		},
		setDefaultFont: (state, action: PayloadAction<FontList>) => {
			state.font = action.payload;
		},
		setText: (state, action: PayloadAction<TextPayload>) => {
			state.statements[action.payload.number].text = action.payload.value;
		},
		setFont: (state, action: PayloadAction<FontPayload>) => {
			state.statements[action.payload.number].font = action.payload.font;
		},
		setWeight: (state, action: PayloadAction<NumberPayload>) => {
			const font = state.statements[action.payload.number].font;
			const weigths = FontWeights.getAll(
				font === FontList.DEFAULT ? state.font : font
			);
			if (weigths.includes(action.payload.value))
				state.statements[action.payload.number].weight = action.payload.value;
		},
		setColor: (state, action: PayloadAction<ColorPayload>) => {
			state.statements[action.payload.number].color = action.payload.color;
		},
		add: (state) => {
			state.statements.push(defaultStatement);
		},
		remove: (state, action: PayloadAction<number>) => {
			state.statements = state.statements.filter(
				(e, i) => i !== action.payload
			);
		},
		reset: (state) => initialState,
		toggleRandomWeights: (state) => {
			state.randomWeights = !state.randomWeights;
		},
	},
});

export const {
	setWidth,
	setHeight,
	setSpacing,
	setDefaultColor,
	setDefaultBackgroundColor,
	setDefaultFont,
	setColor,
	setFont,
	setWeight,
	setText,
	add,
	remove,
	reset,
	toggleRandomWeights,
} = FormSlice.actions;

export default FormSlice.reducer;
