import { XorShift128 } from "../../xorshift128";
import { LayoutMap } from "./layoutMap";

export interface Box {
	x: number;
	y: number;
	width: number;
	height: number;
}

interface MultiBox {
	boxes: Box[];
	normalizedArea: number;
}

interface BiBox {
	horizontal: MultiBox;
	vertical?: MultiBox;
}

export enum Direction {
	HORIZONTAL = "Horizontal",
	VERTICAL = "Vertical",
}

interface LocalDensity {
	x: number;
	y: number;
	density: number;
}

export class BoxLayouter {
	private rand: XorShift128;

	private _width: number;
	private _height: number;

	private layoutMap: LayoutMap;

	private xCandidates = new Set<number>([0]);
	private yCandidates = new Set<number>([0]);
	private localDensityMapL = new Map<string, LocalDensity>();
	private localDensitiesL: LocalDensity[] = [];
	private localDensityMapR = new Map<string, LocalDensity>();
	private localDensitiesR: LocalDensity[] = [];
	private ldWide;
	private spacing;

	constructor({
		width,
		height,
		spacing = 1,
		ldwide = 10,
		seed,
	}: {
		width: number;
		height: number;
		spacing?: number;
		ldwide?: number;
		seed: number;
	}) {
		this._width = Math.round(width);
		this._height = Math.round(height);

		this.layoutMap = new LayoutMap(this._width, this._height);

		this.rand = new XorShift128(seed);

		this.ldWide = ldwide;
		this.spacing = spacing;
		this.xCandidates.add(this._width);
		this.xCandidates.add(Math.round(this._width / 2));
		this.yCandidates.add(this._height);
		this.yCandidates.add(Math.round(this._height / 2));
		this.updateLocalDensities({
			x: 0,
			y: 0,
			width: this._width,
			height: this._height,
		});
	}

	get width() {
		return this._width;
	}
	get height() {
		return this._height;
	}

	layout = (biboxes: BiBox[]) => {
		let pendingArea = biboxes.reduce(
			(sum, bibox) => sum + this.getMinimumArea(bibox),
			0
		);

		const shuffled = this.rand.shuffle(
			biboxes.map((bibox, i) => {
				return { bibox, index: i };
			})
		);
		const results = shuffled.map((b) => {
			const factor = 0.5 + this.rand.rand();
			const scale = Math.max(
				1,
				Math.round(Math.sqrt(this.layoutMap.emptyNum / pendingArea) * factor)
			);

			const placement = this.findFittestPlacement(b.bibox, scale);
			if (placement) {
				placement.boxes.forEach((box) => {
					this.setBox(box);
				});
				pendingArea -= this.getMinimumArea(b.bibox);
				// this.layoutMap.log();
				placement.boxes = BoxLayouter.centeringBoxes(
					placement.boxes,
					this.spacing
				);
			}
			return { placement, index: b.index };
		});

		return results.sort((a, b) => a.index - b.index).map((p) => p.placement);
	};

	private getMinimumArea = (bibox: BiBox) =>
		bibox.vertical
			? Math.min(bibox.horizontal.normalizedArea, bibox.vertical.normalizedArea)
			: bibox.horizontal.normalizedArea;

	private findFittestPlacement = (bibox: BiBox, initialScale: number) => {
		let scale = initialScale;

		while (scale > 0) {
			const horizontal = this.findMultiBoxLocation(bibox.horizontal, scale);

			const vertical = bibox.vertical
				? this.findMultiBoxLocation(bibox.vertical, scale)
				: null;

			let direction: Direction | undefined = undefined;
			if (horizontal && vertical) {
				if (horizontal.density < vertical.density)
					direction = Direction.HORIZONTAL;
				else if (horizontal.density > vertical.density)
					direction = Direction.VERTICAL;
				else
					direction =
						this.rand.randInt(2) === 0
							? Direction.HORIZONTAL
							: Direction.VERTICAL;
			} else if (horizontal) direction = Direction.HORIZONTAL;
			else if (vertical) direction = Direction.VERTICAL;

			if (direction === Direction.HORIZONTAL && horizontal)
				return {
					direction,
					scale,
					boxes: horizontal.boxes,
				};
			else if (direction === Direction.VERTICAL && vertical)
				return {
					direction,
					scale,
					boxes: vertical.boxes,
				};

			scale--;
		}
	};

	static scaleBoxes = (boxes: Box[], scale = 1) => {
		return boxes.map<Box>((box) => {
			return {
				x: box.x * scale,
				y: box.y * scale,
				width: box.width * scale,
				height: box.height * scale,
			};
		});
	};

	static paddingBoxes = (boxes: Box[], padding: number = 1) => {
		return boxes.map<Box>((box) => {
			return {
				x: box.x,
				y: box.y,
				width: box.width + (box.width < 0 ? -2 : 2) * padding,
				height: box.height + 2 * padding,
			};
		});
	};

	static centeringBoxes = (boxes: Box[], padding: number = 1) => {
		return boxes.map<Box>((box) => {
			return {
				x: box.x + (box.width < 0 ? -padding : padding),
				y: box.y + 1,
				width: box.width + (box.width < 0 ? 2 : -2) * padding,
				height: box.height - 2 * padding,
			};
		});
	};

	private findMultiBoxLocation = (multibox: MultiBox, scale = 1) => {
		const scaled = BoxLayouter.paddingBoxes(
			BoxLayouter.scaleBoxes(multibox.boxes, scale),
			this.spacing
		);

		const localDensities =
			multibox.boxes[0].width < 0 ? this.localDensitiesR : this.localDensitiesL;

		const candidate = localDensities.find((candidate) => {
			return scaled.every((box) =>
				this.layoutMap.isEmpty(
					candidate.x + box.x,
					candidate.y + box.y,
					box.width,
					box.height
				)
			);
		});

		if (candidate) {
			const boxes = scaled.map((box) => {
				return {
					x: candidate.x + box.x,
					y: candidate.y + box.y,
					width: box.width,
					height: box.height,
				};
			});
			return { boxes, density: candidate.density };
		}
	};

	setBox = (box: Box) => {
		this.layoutMap.fulfill(box.x, box.y, box.width, box.height);

		this.xCandidates.add(Math.max(0, Math.min(box.x, this._width)));
		this.xCandidates.add(Math.max(0, Math.min(box.x + box.width, this._width)));
		this.yCandidates.add(Math.max(0, Math.min(box.y, this._height)));
		this.yCandidates.add(
			Math.max(0, Math.min(box.y + box.height, this._height))
		);

		this.updateLocalDensities(box);
	};

	private findAffectedPositions = (
		left: number,
		right: number,
		top: number,
		bottom: number
	) => {
		const affectedX = [...this.xCandidates].filter(
			(x) => left <= x && x <= right
		);
		const affectedY = [...this.yCandidates].filter(
			(y) => top <= y && y <= bottom
		);
		return affectedX
			.map((x) => {
				return affectedY.map((y) => {
					return {
						x,
						y,
					};
				});
			})
			.flat();
	};

	private updateLocalDensities = (box: Box) => {
		const inverted = {
			...box,
			x: box.x + box.width,
			width: -box.width,
		};

		this.updateLocalDensitiesL(box.width >= 0 ? box : inverted);
		this.updateLocalDensitiesR(box.width < 0 ? box : inverted);
	};

	private updateLocalDensitiesL = (box: Box) => {
		const right = box.x + box.width;
		const left = box.x - this.ldWide;
		const bottom = box.y + box.height;
		const top = box.y - this.ldWide;
		const positions = this.findAffectedPositions(left, right, top, bottom);

		positions.forEach((pos) => {
			this.localDensityMapL.set(`${pos.x},${pos.y}`, {
				x: pos.x,
				y: pos.y,
				density: this.layoutMap.getLocalDensity(
					pos.x,
					pos.y,
					this.ldWide,
					this.ldWide
				),
			});
		});
		this.localDensitiesL = [...this.localDensityMapL.values()]
			.filter((ld) => ld.density !== 1)
			.sort((a, b) => a.density - b.density);
	};

	private updateLocalDensitiesR = (box: Box) => {
		const right = box.x;
		const left = box.x + box.width - this.ldWide;
		const bottom = box.y + box.height;
		const top = box.y - this.ldWide;
		const positions = this.findAffectedPositions(left, right, top, bottom);

		positions.forEach((pos) => {
			this.localDensityMapR.set(`${pos.x},${pos.y}`, {
				x: pos.x,
				y: pos.y,
				density: this.layoutMap.getLocalDensity(
					pos.x,
					pos.y,
					-this.ldWide,
					this.ldWide
				),
			});
		});

		this.localDensitiesR = [...this.localDensityMapR.values()]
			.filter((ld) => ld.density !== 1)
			.sort((a, b) => a.density - b.density);
	};
}
