import { MenuItem, Select } from "@mui/material";
import { useState } from "react";

type ArraySelectorProps = {
    array:string[];
    initial: number;
    onChange:(value:string, index:number)=>void;
    item: (value:string, index:number)=>JSX.Element;
}

export const ArraySelector = (args:ArraySelectorProps) => {
    const initialIndex = Math.min(Math.max(0, Math.round(args.initial)), args.array.length-1);
    const [index, setIndex] = useState(initialIndex);
    const handleChange = (i:number) => {
        setIndex(i);
        args.onChange(args.array[i], i);
    }

    return (<>
            <Select 
            color='primary' variant='standard'
            value={index}
            onChange={(e)=>handleChange(e.target.value as number)}>
                {args.array.map((value, index)=>
                <MenuItem value={index} key={index}>
                    {args.item(value, index)}
                </MenuItem>)}
            </Select>
        </>
        );
}