import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import formReducer from "../features/form/formSlice";
import resultImageReducer from "../features/result/resultImageSlice";
import { saveState } from "../features/localStorage";

export const store = configureStore({
	reducer: {
		form: formReducer,
		result: resultImageReducer,
	},
});

store.subscribe(() => {
	saveState(store.getState().form);
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
