import {　Grid, Paper, Typography, Divider, Link, Box } from "@mui/material"
import { PageBase } from "./PageBase";

interface FontInfo {
    font:string;
    url:string;
}
const usedFonts:FontInfo[] = [
    {font:'Dela Gothic One', url:'https://github.com/syakuzen/DelaGothic'},
    {font:'DotGothic16', url:'https://github.com/fontworks-fonts/DotGothic16'},
    {font:'Hina Mincho', url:'https://github.com/satsuyako/Hina-Mincho'},
    {font:'Kaisei Decol', url:'https://github.com/FontKai-Kaisei/Kaisei#-%E8%A7%A3%E6%98%9F%E3%83%87%E3%82%B3%E3%83%BC%E3%83%AB'},
    {font:'Kaisei Tokumin', url:'https://github.com/FontKai-Kaisei/Kaisei#-%E8%A7%A3%E6%98%9F-%E7%89%B9%E3%83%9F%E3%83%B3'},
    {font:'Kiwi Maru', url:'https://github.com/Kiwi-KawagotoKajiru/Kiwi-Maru'},
    {font:'Klee One', url:'https://github.com/fontworks-fonts/Klee'},
    {font:'M PLUS Rounded 1c', url:'http://jikasei.me/font/rounded-mplus'},
    {font:'Noto Sans JP', url:'https://www.google.com/get/noto/'},
    {font:'Noto Serif JP', url:'https://www.google.com/get/noto/'},
    {font:'Rampart One', url:'https://github.com/fontworks-fonts/Rampart'},
    {font:'Reggae One', url:'https://github.com/fontworks-fonts/Reggae'},
    {font:'RocknRoll One', url:'https://github.com/fontworks-fonts/RocknRoll'},
    {font:'Stick', url:'https://github.com/fontworks-fonts/Stick'},
    {font:'Train One', url:'https://github.com/fontworks-fonts/Train'},
    {font:'Yomogi', url:'https://github.com/satsuyako/YomogiFont'},
];

export const About = () => {

    return(
    <PageBase>
        <Grid container spacing={2} alignItems="center" direction='column'>

        <Grid item >
            <Paper sx={{padding:2, textAlign:'justify'}}>
                <Typography variant='h5'>このサイトについて</Typography>
                <Divider />
                <Typography variant='body1' sx={{paddingY:2}}>
                    発言まとめメーカーは、複数の文字列を一枚の画像にレイアウトして描画することを目的としたWebアプリケーションです。
                    手軽に発言をまとめたり、語録を画像として出力できます。
                    <br />
                    このサイトは <Link href="https://twitter.com/AppykNo" underline="hover" target="_blank" rel="noopener">
                    {'@AppykNo'}</Link> によって開発・運営されています。バグや不具合等を発見した場合はお手数ですが、当ツイッターアカウントまでご連絡ください。
                </Typography>

            </Paper>
        </Grid>

        <Grid item >
            <Paper sx={{padding:2, textAlign:'justify'}}>
                <Typography variant='h5'>フォントについて</Typography>
                <Divider />
                <Box sx={{paddingY:2}}>

                <Typography variant='body1'>
                    発言まとめメーカーでは、画像の描画について以下のフォントおよびそのサブセットフォントを利用しています。
                </Typography>
                    <ul>
                        {usedFonts.map((fontInfo)=>{
                            return (
                                <li key={fontInfo.font}>
                                    <Link underline="hover" target="_blank" rel="noopener" href={fontInfo.url}>
                                        {fontInfo.font}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                <Typography variant='body1'>
                    また、サブセットフォントについては全て 
                    <Link underline="hover" target="_blank" rel="noopener" href='https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL'>
                        SIL Open Font License
                    </Link>
                     に従うものとします。
                </Typography>
                
                </Box>
            </Paper>
        </Grid>


        </Grid>
    </PageBase>);
}