import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material';
import { Home } from './Home';
import { About } from './About';
import { Usage } from './Usage';
import { Policy } from './Policy';
import { Gtag } from './features/Gtag';

const theme = createTheme({
  palette:{
    primary:{main:'#65318e'},
    secondary:{main:'#ffd900'},
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      '"Hiragino Sans"',
      'Arial',
      '"Yu Gothic"',
      'Meiryo',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Noto Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Gtag>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/about" exact component={About} />
              <Route path="/usage" exact component={Usage} />
              <Route path="/policy" exact component={Policy} />
              <Redirect to="/" />
            </Switch>
          </Gtag>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
