import { TextField, Grid, Typography } from "@mui/material"
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { toNum } from "../../util";
import { ArraySelector } from "./ArraySelector";
import { setHeight, setWidth } from "./formSlice";

export interface AspectRate {
    width:number;
    height: number;
}
const aspectRates = [
    {height:720, width:1280, rate:'9:16'}, 
    {height:1080, width:1080, rate:'1:1'},
    {height:1440, width:1280, rate:'9:8'},
    {height:1024, width:768, rate:'4:3'},
];


export const ImageSizeSettings = ()=>{

    const width = useAppSelector((state)=>state.form.width);
    const height = useAppSelector((state)=>state.form.height);

    const dispatch = useAppDispatch();

    const initialIndex = aspectRates.findIndex((r)=>r.width===width&&r.height===height);
    const [showInputs, setShowInputs] = useState(initialIndex===-1);

    return (
        <Grid container direction="row" justifyContent='center' alignItems="flex-end">
        <Grid item>
            <Typography variant='body1'>縦横比:</Typography>
        </Grid>
        <Grid item sx={{paddingX:1}} >
            <ArraySelector
            initial={initialIndex!==-1?initialIndex:aspectRates.length}
            array={[...aspectRates.map(r=>r.rate), 'カスタム']}
            item={(v)=><div >{v}</div>}
            onChange={(_, i)=>{
                if(i===aspectRates.length)setShowInputs(true);
                else {
                    setShowInputs(false);
                    dispatch(setWidth(aspectRates[i].height));
                    dispatch(setHeight(aspectRates[i].width));

                }
            }}
            />
        </Grid>
            <Grid item>
                <Grid container direction="row" justifyContent='center' alignItems="flex-end">
                {showInputs ? <ImageSizeInput label='高さ' size={height} onChange={h=>dispatch(setHeight(h))}/> : null}
                {showInputs ? <ImageSizeInput label='幅' size={width} onChange={w=>dispatch(setWidth(w))}/> : null}
                </Grid>
            </Grid>
        </Grid>
    )
}





const ImageSizeInput = ({label, size, onChange}:{label:string, size:number, onChange:(size:number)=>void}) => {
    return (
    <Grid item sx={{paddingX:1}} >
        <TextField type='number'color='primary' variant='standard' size='small'
            sx={{maxWidth:60}}
            label={label} 
            value={size}
            onChange={(e) => onChange(toNum(e.target.value))}/>
    </Grid>
    );
}