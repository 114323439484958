import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { store } from "../../app/store";
import { Color } from "../color";
import { FontList, FontWeights } from "../fontList";
import { createImage, StatementInput } from "../result/renderer";
import { failedRendering, setLog, startRendering, successRendering } from "../result/resultImageSlice";
import { FormState, initialState } from "./formSlice";

const toSeed = (num:string)=>{
    const number = Number(num);
    return isNaN(number) ? Date.now() : number;
}

const getDefaultStatement = (font:FontList, color:Color) => {
    return {
        texts: ["発言を入力してください"],
        font: font,
        weight: 400,
        color: color,
    };
};

const getStatements = (state:FormState, seed:number = 0) => {
    const statements = state.statements.filter((s)=>s.text!=='');
    if(statements.length===0){
        const defaultStatement = getDefaultStatement(state.font, state.color);
        const defaultStatements = [...Array(100)].map(() => defaultStatement);
        return setWeight(seed, defaultStatements);
    }

    const inputs = statements.map((s)=>{
        return {
            texts: s.text.normalize().split(/\r?\n/),
            font: s.font===FontList.DEFAULT ? state.font : s.font,
            weight: s.weight,
            color: s.color ?? state.color,
        };
    });
    return state.randomWeights ? setWeight(seed, inputs) : inputs;

}

const setWeight = (seed:number, statements:{
    texts: string[],
    font: FontList,
    weight: number,
    color: Color,
}[]) => {
    const weigths = new FontWeights(seed);
    return statements.map<StatementInput>((statement)=>{
        return {
            ...statement,
            weight:weigths.getRandom(statement.font)
        };
    })
}


export const SubmitButton = ({isAdvanced}:{isAdvanced:boolean})=>{
    const [isClicked, setIsClicked] = useState(false);
    const [seed, setSeed] = useState(Date.now());

    const dispatch = useAppDispatch();

    const handleSubmit = async (seed:number) => {
        const state = isAdvanced ? store.getState().form : {
            ...initialState,
            statements: store.getState().form.statements
        };
        const statements = getStatements(state, seed)

        try{
            dispatch(startRendering());         
            const result = await createImage({
                width: state.width,
                height: state.height,
                statements,
                seed,
                defaultColor: state.color,
                backgroundColor: state.backgroundColor,
                spacing: state.spacing,
            },
            {
                onFontLoadStart: () => dispatch(setLog("フォントのダウンロード中")),
                onRenderingStart: () => dispatch(setLog("発言のレイアウト中")),
                onImageCreationStart: () => dispatch(setLog("画像の作成中")),
            });
            dispatch(successRendering(result));
        } catch(error){
            dispatch(failedRendering(`${error}`));
        }
    }

    if(isAdvanced)
        return (
            <Box display="flex" justifyContent='center' padding={2}>
                <TextField type='number' label="シード値" color='primary' variant='standard'
                  value={seed} onChange={(e) => setSeed(toSeed(e.target.value))}/>
                <Button color="primary" onClick={()=>{
                    setSeed(Date.now());
                }}>更新</Button>
                <Button variant="contained" color="secondary" type="submit"
                 onClick={()=>handleSubmit(seed)}>
                     作成
                </Button>
            </Box>
        );
    return(
        <Box display="flex" justifyContent='center' padding={2}>
            <Button variant="contained" color="secondary"
             type="submit" onClick={()=>{
                 setIsClicked(true);
                 handleSubmit(Date.now());
            }}>
                 {isClicked? '更新':'作成'}
            </Button>
        </Box>
    );
}
