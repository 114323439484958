import {　Grid, Paper, Typography, Divider, Box, Link } from "@mui/material"

import { PageBase } from "./PageBase";

export const Policy = () => {

    return(
    <PageBase>
        <Grid container spacing={2} alignItems="center" direction='column'>

        <Grid item >
            <Paper sx={{padding:2, textAlign:'justify'}}>
                <Typography variant='h5'>プライバシーポリシー</Typography>
                <Divider />
                <Box sx={{paddingY:2}}>
                    <Typography variant='h6'>アクセス解析サービスの利用について</Typography>
                    <Typography variant='body1' sx={{paddingY:1}}>
                    発言まとめメーカーでは、Googleによるアクセス解析ツールであるGoogleアナリティクスを利用しています。
                    Googleアナリティクスはアクセス情報の収集のためにCookieを利用することがあります。
                    このアクセス情報は匿名で収集されており、個人を特定するものではありません。
                    GoogleアナリティクスのCookieは、26ヶ月間保持されます。
                    ブラウザの設定からCookieを無効にすることで、これらの情報の収集を拒否することが可能です。
                    Googleアナリティクスの利用規約は、
                    <Link href="https://marketingplatform.google.com/about/analytics/terms/jp/" underline="hover"  target="_blank" rel="noopener">
                        こちら
                    </Link>
                    をご確認ください。
                    また、「ユーザーがGoogleパートナーのサイトやアプリを使用する際のGoogleによるデータ使用」に関しては、
                    <Link href="https://policies.google.com/technologies/partner-sites?hl=ja" underline="hover"  target="_blank" rel="noopener">
                        こちら
                    </Link>
                    をご確認ください。
                    </Typography>

                    <Typography variant='body1' sx={{paddingY:1}}>
                    発言まとめメーカーでは、Microsoftによるアクセス解析ツールであるMicrosoft Clarityを利用しています。
                    このサービスは、
                    <Link href="https://privacy.microsoft.com/ja-jp/privacystatement" target="_blank" rel="noopener">
                        Microsoftプライバシーステートメント
                    </Link>に基づき運用されています。
                    </Typography>
                </Box>

                <Box sx={{paddingY:2}}>
                    <Typography variant='h6'>広告サービスの利用について</Typography>
                    <Typography variant='body1' sx={{paddingY:1}}>
                    発言まとめメーカーでは、第三者配信の広告サービスであるGoogle Adsenseを利用します。
                    Googleを含む第三者広告配信事業者は、訪問者の興味に応じた広告を表示するためにCookieを使用することがあります。
                    訪問者は、広告設定で訪問者に合わせた広告（以下、パーソナライズド広告）を無効にすることができます。
                    無効にしたい場合は
                    <Link href="https://adssettings.google.com/authenticated" underline="hover"  target="_blank" rel="noopener">
                        こちら
                    </Link>からその設定をすることができます。
                    また、
                    <Link href="https://www.aboutads.info" target="_blank" rel="noopener">
                        www.aboutads.info
                    </Link>からパーソナライズド広告に使用される第三者配信事業者のCookie を無効にすることができます。
                    第三者配信による広告掲載を無効にしていない場合は、第三者配信事業者や広告ネットワークの配信する広告がサイトに掲載されることがあります。
                    Googleによって広告の第三者配信が認められている広告配信事業者の詳細は、
                    <Link href="https://support.google.com/admanager/answer/94149" underline="hover"  target="_blank" rel="noopener">
                        こちら
                    </Link>から確認することができます。
                    </Typography>
                </Box>
            </Paper>
        </Grid>

        </Grid>
    </PageBase>);
}