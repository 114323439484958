import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, Link, Typography } from '@mui/material';
import Ads from "./features/Ads";


// https://qiita.com/hideokaizuka/items/43d96e21b3d7cd7d0240
const MenuLink = ({to, text}:{to:string, text:string}) => {
    return (
      <Link
        component={RouterLink}
        to={to}
        color="inherit"
        underline="hover"
      >
        {text}
      </Link> );
  }
  

export const PageBase: React.FC = (props) => {
    return (<div>
    <Box bgcolor='white'>
        <picture>
            <source srcSet={`${process.env.PUBLIC_URL}/title_mobile.webp`} media="(max-width: 600px)" type="image/webp" />
            <source srcSet={`${process.env.PUBLIC_URL}/title.webp`} media="(min-width: 601px)" type="image/webp" />
            <source srcSet={`${process.env.PUBLIC_URL}/title_mobile.jpg`} media="(max-width: 600px)" type="image/jpeg" />
            <source srcSet={`${process.env.PUBLIC_URL}/title.png`} media="(min-width: 601px)" type="image/png" />
            <img className='responsive-img' src={`${process.env.PUBLIC_URL}/title.png`}
            width='1500' height='800'
            style={{ pointerEvents:'none'}} alt='発言まとめメーカー'/>
        </picture>
    </Box>
    <Box bgcolor='#302833' color='white' marginTop={1} padding={2}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
            <MenuLink to='/' text='ホーム' />
        </Grid>
        <Grid item>
            <MenuLink to='/about' text='このサイトについて' />
        </Grid>
        <Grid item>
            <MenuLink to='/usage' text='使い方' />
        </Grid>
        <Grid item>
            <MenuLink to='/policy' text='プライバシーポリシー' />
        </Grid>
        </Grid>
    </Box>
    <Box bgcolor='primary.main' paddingY={3}>

        <Box bgcolor='#ede7f6' paddingX={2} paddingY={4}>
            <Box sx={{paddingY:2}}>
                <Ads slot='6309776060' format='horizontal'/>
            </Box>
            {props.children && <div>{props.children}</div>}
            <Box sx={{paddingY:2}}>
                <Ads slot='2750351491' format='horizontal'/>
            </Box>
        </Box>

    </Box>

    <Box bgcolor='#302833' color='white' padding={2}>
    <Typography align='center'>発言まとめメーカー</Typography>
    <Typography align='center'>{'Copyright © 2019-2022 '} 
        <Link color='inherit' href="https://twitter.com/AppykNo" underline="hover" target="_blank" rel="noopener">
            {'@AppykNo'}
        </Link>{" All Rights Reserved."}</Typography>
    </Box>
    </div>);

}