import { RGBColor } from "react-color";

export interface Color {
	r: number;
	g: number;
	b: number;
	a: number;
	hex: string;
}

export const toHex = (color: RGBColor) => {
	const alpha = getAlpha(color.a);
	const hexes = [color.r, color.g, color.b, alpha].map((v) =>
		("0" + v.toString(16)).slice(-2)
	);
	return "#" + hexes.join("");
};

const getAlpha = (opacity: number | undefined) => {
	return opacity ? Math.round(opacity * 255) : 255;
};

export const fromRGBColor = (color: RGBColor): Color => {
	return {
		r: color.r,
		g: color.g,
		b: color.b,
		a: getAlpha(color.a),
		hex: toHex(color),
	};
};
