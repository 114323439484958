import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface resultState {
	isPending: boolean;
	isSuccess: boolean;
	isFailed: boolean;
	objectUrl: string;
	dataUrl: string;
	error: string;
	log: string;
}

const initialState: resultState = {
	isPending: false,
	isSuccess: false,
	isFailed: false,
	objectUrl: "",
	dataUrl: "",
	error: "",
	log: "",
};

interface RenderingResult {
	objectUrl: string;
	dataUrl: string;
	message: string;
}

export const ResultImageSlice = createSlice({
	name: "result",
	initialState: initialState,
	reducers: {
		startRendering: (state) => {
			state.isPending = true;
			state.isSuccess = false;
			state.isFailed = false;
			state.log = "";
		},
		successRendering: (state, action: PayloadAction<RenderingResult>) => {
			state.isPending = false;
			state.isSuccess = true;
			state.isFailed = false;
			URL.revokeObjectURL(state.objectUrl);
			state.objectUrl = action.payload.objectUrl;
			state.dataUrl = action.payload.dataUrl;
			state.error = action.payload.message;
		},
		failedRendering: (state, action: PayloadAction<string>) => {
			state.isPending = false;
			state.isSuccess = false;
			state.isFailed = true;
			state.error = action.payload;
		},
		setLog: (state, action: PayloadAction<string>) => {
			state.log = action.payload;
		},
	},
});

export const { startRendering, successRendering, failedRendering, setLog } =
	ResultImageSlice.actions;

export default ResultImageSlice.reducer;
