import { Box, Grid, Paper, Typography, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ImageSizeSettings } from "./ImageSizeSettings";
import { DefaultColorPicker } from "./ColorPicker";
import { FontSettings } from "./FontSettings";
import { setDefaultBackgroundColor, setDefaultColor, setSpacing, } from "./formSlice";
import { toNum } from "../../util";


export const SettingsBoard = ()=>{

    return(
        <Grid item xs={12}>
            <Paper elevation={3}>
                <Box padding={2}>
                    <Grid container direction="column" spacing={1}>
                        
                        <Grid item>
                            <ColorSettings />
                        </Grid>

                        <Grid item>
                            <FontSettings />
                        </Grid>

                        <Grid item>
                            <ImageSizeSettings />
                        </Grid>

                        <Grid item>
                            <SpacingSettings />
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Grid>);
}

const ColorSettings = () => {
    const color = useAppSelector((state)=>state.form.color);
    const backgroundColor = useAppSelector((state)=>state.form.backgroundColor);

    const dispatch = useAppDispatch();

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item sx={{paddingX:1}}>
                <DefaultColorPicker disableAlpha label='文字色'
                color={color.hex} setColor={(c)=>dispatch(setDefaultColor(c))}/>
            </Grid>
            <Grid item sx={{paddingX:1}}>
                <DefaultColorPicker label='背景色'
                color={backgroundColor.hex} setColor={(c)=>dispatch(setDefaultBackgroundColor(c))}/>
            </Grid>
        </Grid>
    );
}



const SpacingSettings = () => {
    const spacing = useAppSelector((state)=>state.form.spacing);
    const dispatch = useAppDispatch();

    return (
        <Grid container direction="row" justifyContent='center' alignItems="center">
            <Grid item>
                <Typography variant='body1'>余白: </Typography>
            </Grid>
            <Grid item sx={{paddingX:1}}>
                <TextField type='number'color='primary' variant='standard' size='small'
                sx={{maxWidth:40}}
                value={spacing}
                onChange={(e) => dispatch(setSpacing(toNum(e.target.value)))}/>
            </Grid>
        </Grid>
    );
}