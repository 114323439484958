import {Divider, Typography, Paper, Grid, Box } from "@mui/material"
import { Form } from "./features/form/Form";
import { ResultImage } from "./features/result/ResultImage";
import { PageBase } from "./PageBase";

export const Home = () => {

    return (
    <PageBase>
        <Grid container spacing={2} alignItems="center" direction='column'>

            <Grid item><Updates /></Grid>
            <Grid item><Form /></Grid>
            <Grid item><ResultImage /></Grid>

        </Grid>
    </PageBase>
    );
}

const Updates = () => {
    return (
    <Paper sx={{padding:2, textAlign:'justify'}}>
        <Typography variant='h5'>お知らせ</Typography>
        <Divider />
        <Box sx={{paddingY:2}}>
        <Typography variant='body1'>
            {"画像に挿入される「発言まとめメーカー」のマークの色がデフォルト文字色に依存して変わるようにしました。"}
        </Typography>
        <Typography variant='body1'>
            {"画像生成の進行状況を簡易的に表示するようにしました。"}
        </Typography>
        <Typography variant='body2'>{'(2022-01-16)'}</Typography>
        </Box>
    </Paper>
    );
}