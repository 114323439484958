import { Alert, AlertTitle, Box, Button, CircularProgress, Paper, Typography, Link } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useAppSelector } from "../../app/hooks";

export const ResultImage = () => {

    const input = useAppSelector((state)=>state.result);

    if(input.isPending){
        return (
            <div>
        <Paper>
            <Box padding={2}>
                 <CircularProgress color='primary'/>
                 <Typography variant='body1'>{input.log}</Typography>
            </Box>
        </Paper>
    </div>);
    }
    if(input.isSuccess||input.isFailed){
    return (
    <div>
        <Paper>
            <Box padding={2}> 
            {input.dataUrl ? <ImageArea src={input.dataUrl}/> : null}
            {input.objectUrl ? <DownloadButton src={input.objectUrl}/> : null}
            {input.error!=='' ? <ErrorArea error={input.error}></ErrorArea>:<TweetArea />}      
            </Box>
        </Paper>
    </div>);
    }
    return(<div></div>)
}

const ImageArea = ({src}:{src:string}) => {
    return (
    <img className='responsive-img'
     style={{border:'1px solid black'}}
     src={src}
     alt='画像を表示できませんでした。'/>
    );
}

const DownloadButton = ({src}:{src:string}) => {
    const filename = Date.now() + '.png';

    return (
        <Box padding={2}>
            <Button variant="contained" color='secondary'
            startIcon={<SaveAltIcon />} 
            href={src} target="_blank" download={filename}>
                ダウンロード
            </Button>
        </Box>
    );
}

const ErrorArea  = ({error}:{error:string}) => {
    return (
    <Box padding={2}>
        <Alert severity="error">
            <AlertTitle>エラーが発生しました。</AlertTitle>
            <Typography variant='body1' style={{whiteSpace: 'pre-line'}}>
                {error}
            </Typography>
        </Alert>
    </Box>);
}


const TweetArea = () => {

    const hashtag = '発言まとめメーカー'
    const url = 'https://hatsugen-matome-maker.yukihiro.work/';
    const link = `https://twitter.com/intent/tweet?hashtags=${hashtag}&url=${url}`;

    return (
        <Box padding={2} textAlign='justify'>
        <Alert severity="info">
            <Typography variant='body1' style={{whiteSpace: 'pre-line'}}>
                {'作成した画像を保存して、「'}
                <Link color='inherit' href={encodeURI(link)} target="_blank" rel='noopener'>
                    {'#発言まとめメーカー'}
                </Link>{'」でツイートしよう！'}
            </Typography>
        </Alert>
    </Box>
    );
}