import { useEffect } from 'react';
declare global { interface Window { adsbygoogle: any} }


const Ads = ({slot, format}:{slot:string, format:string}) => {
  useEffect(() => {
    try{
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
    } catch {

    }
  }, []);

  return (
    <ins className="adsbygoogle"
    style={{display:"block"}}
    data-ad-client="ca-pub-3604205555008751"
    data-ad-slot={slot}
    data-ad-format={format}
    data-full-width-responsive="true"></ins>
  );
};
export default Ads;