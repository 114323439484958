import { XorShift128 } from "../xorshift128";
import { StatementInput } from "./result/renderer";

export enum FontList {
	DEFAULT = "既定のフォント",
	NOTO_SERIF = "Noto Serif JP",
	NOTO_SANS = "Noto Sans JP",
	M_PLUS = "M PLUS Rounded 1c",
	KAISEI_DECOL = "Kaisei Decol",
	KAISEI_TOKUMIN = "Kaisei Tokumin",
	DELA_GOTHIC = "Dela Gothic One",
	DOT_GOTHIC = "DotGothic16",
	HINA = "Hina Mincho",
	KIWI = "Kiwi Maru",
	KLEE = "Klee One",
	RAMPART = "Rampart One",
	REGGAE = "Reggae One",
	ROCKN_ROLL = "RocknRoll One",
	STICK = "Stick",
	TRAIN = "Train One",
	YOMOGI = "Yomogi",
}

export class FontWeights {
	random: XorShift128;

	constructor(seed: number = 0) {
		this.random = new XorShift128(seed);
	}

	getRandom = (font: FontList) => {
		switch (font) {
			case FontList.NOTO_SERIF:
			case FontList.NOTO_SANS:
			case FontList.M_PLUS:
			case FontList.KAISEI_DECOL:
			case FontList.KAISEI_TOKUMIN:
				return this.random.randInt(4) === 0 ? 700 : 400;
			default:
				return 400;
		}
	};

	static getAll = (font: FontList) => {
		switch (font) {
			case FontList.NOTO_SERIF:
			case FontList.NOTO_SANS:
			case FontList.M_PLUS:
			case FontList.KAISEI_DECOL:
			case FontList.KAISEI_TOKUMIN:
				return [400, 700];
			default:
				return [400];
		}
	};
}

export const canBeVertical = (statement: StatementInput) => {
	if (statement.texts.some((t) => hasChar(t, HORIZONTAL_CHARS))) return false;

	if (
		statement.font === FontList.KAISEI_DECOL ||
		statement.font === FontList.KAISEI_TOKUMIN
	) {
		return !statement.texts.some((t) => hasChar(t, KAISEI_HORIZONTAL_CHARS));
	} else if (statement.font === FontList.M_PLUS) {
		return !statement.texts.some((t) => hasChar(t, M_PLUS_HORIZONTAL_CHARS));
	} else if (statement.font === FontList.HINA) {
		return !statement.texts.some((t) => hasChar(t, HINA_HORIZONTAL_CHARS));
	} else if (statement.font === FontList.DELA_GOTHIC) {
		return !statement.texts.some((t) =>
			hasChar(t, DELA_GOTHIC_HORIZONTAL_CHARS)
		);
	} else if (statement.font === FontList.YOMOGI) {
		return !statement.texts.some((t) => hasChar(t, YOMOGI_HORIZONTAL_CHARS));
	}

	return true;
};

const hasChar = (text: string, chars: string[]) => {
	return [...text].some((char) => chars.includes(char));
};

// 縦書きにおいて正立で描画されるべきでない文字種
// 参考: https://hydrocul.github.io/wiki/blog/2014/1101-hyphen-minus-wave-tilde.html
const HORIZONTAL_CHARS = [
	...`~˜˷⁓∼∽﹋﹏-﹣－‐‑⁃˗−⧿➖‒–—―⸺⸻﹘⎯⏤ｰ_ﾞ゛ﾟ゜,.:;,(){}‘’“”"'∥`,
];

// 縦書きグリフになってない文字種
const M_PLUS_HORIZONTAL_CHARS = [..."━│┗┘┛├┣┤┨┫┬┯┰┸┻┼┿╂〓゛゜゠｟｠"];
const HINA_HORIZONTAL_CHARS = [..."‥…∥〜〝〟"];
const DELA_GOTHIC_HORIZONTAL_CHARS = [
	..."―‥…〈〉《》『』【】〔〕〖〗〘〙〜（），．＝［］＿｛｜｝｟｠",
];
const YOMOGI_HORIZONTAL_CHARS = [..."…∥゠"];
const KAISEI_HORIZONTAL_CHARS = [
	..."‥…〓〜〝〟゛゜ー㌀㌃㌕㌖㌘㌞㌢㌪㌫㌱㌳㌶㌻㍂㍇㍉㍊㍍㍎㍑㍗．＝｜～￣",
];

export const getVeriticalText = (text: string, font: FontList) => {
	if (font === FontList.M_PLUS) {
		return [...text]
			.map((char) => MPLUS_SUBSTITUTION_MAP.get(char) ?? char)
			.join("");
	} else if (font === FontList.HINA) {
		return [...text]
			.map((char) => HINA_SUBSTITUTION_MAP.get(char) ?? char)
			.join("");
	} else if (font === FontList.YOMOGI) {
		return [...text]
			.map((char) => YOMOGI_SUBSTITUTION_MAP.get(char) ?? char)
			.join("");
	} else if (
		font === FontList.KAISEI_DECOL ||
		font === FontList.KAISEI_TOKUMIN
	) {
		return [...text]
			.map((char) => KAISEI_SUBSTITUTION_MAP.get(char) ?? char)
			.join("");
	}
	return text;
};

// 縦書きグリフを代替できる文字種
const MPLUS_SUBSTITUTION_MAP = new Map([
	["‥", "︰"],
	["…", "︙"],
	["↑", "←"],
	["→", "↑"],
	["↓", "→"],
	["←", "↓"],
]);
const HINA_SUBSTITUTION_MAP = new Map([["―", "︱"]]);
const YOMOGI_SUBSTITUTION_MAP = new Map([
	["―", "︲"],
	["‥", "︰"],
]);
const KAISEI_SUBSTITUTION_MAP = new Map([
	["、", "︑"],
	["。", "︒"],

	["，", "︐"],
	["―", "︱"],
	["＿", "︳"],
	["（", "︵"],
	["）", "︶"],
	["｛", "︷"],
	["｝", "︸"],
	["〈", "︿"],
	["〉", "﹀"],
	["「", "﹁"],
	["」", "﹂"],
	["［", "﹇"],
	["］", "﹈"],
	["〔", "︹"],
	["〕", "︺"],
	["【", "︻"],
	["】", "︼"],
	["《", "︽"],
	["》", "︾"],
	["『", "﹃"],
	["』", "﹄"],
]);
