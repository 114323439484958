import { useState } from 'react'
import { Box, Chip, Grid, IconButton,Popover,Switch, Typography } from '@mui/material';

import { SketchPicker } from 'react-color';
import { Brightness1, FormatColorText } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setColor } from './formSlice';
import { Color, fromRGBColor } from '../color';

const getStatementColor = (isChecked:boolean,  defaultColor:Color, color:Color|null)=>{
  if(isChecked || !color)return defaultColor;
  return color;
}

const presetColors = ["#000000", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", 
"#4caf50", "#8bc34a", "#cddc39", "#ffff00", "#ffc107", "#ff9800", "#ff5722", "#ffffff",];

const dropShadow = 'drop-shadow(0px 0px 3px #65318e88)';

export const StatementColorPicker = ({number}:{number:number}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const defaultColor = useAppSelector((state)=>state.form.color);
    const color = useAppSelector((state)=>state.form.statements[number].color);
    const hexColor = color?.hex ?? defaultColor.hex;
    const dispatch = useAppDispatch();

    return (
      <div>
        <IconButton size='small' onClick={(e)=>setAnchorEl(e.currentTarget)}>
          <FormatColorText fontSize='small'
          style={{color:hexColor, filter:dropShadow}}/>
        </IconButton>
      <Popover
        open={!!anchorEl}
        onClose={()=>setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker disableAlpha color={hexColor} presetColors={presetColors} 
        styles={{default:{picker:{boxShadow:'none'}}}}
        onChange={(c)=>dispatch(setColor({number, color: fromRGBColor(c.rgb)}))} />
          <Grid container>
            <Grid item xs={8}>
            <Box paddingTop={1} paddingLeft={2}>
            <Typography variant="body2">既定の文字色を使用</Typography>
            </Box>
            </Grid>
            <Grid item xs={4}>
            <Switch checked={!color} 
            onChange={(e)=>dispatch(setColor({
              number,
              color:getStatementColor(e.target.checked, defaultColor, color)}))}/>
            </Grid>
          </Grid>
      </Popover>
      </div>
    );
  
}

export const DefaultColorPicker = ({label, color, setColor, disableAlpha=false}
  :{label:string, color:string, setColor:(color: Color)=>void, disableAlpha?:boolean}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Box >
      <Chip
          avatar={<Brightness1 style={{color:color, filter:dropShadow}}/>}
          label={label}
          onClick={(e)=>setAnchorEl(e.currentTarget)}
          variant="outlined"
      />
      <Popover
        open={!!anchorEl}
        onClose={()=>setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker disableAlpha={disableAlpha}
         color={color} presetColors={presetColors} 
        styles={{default:{picker:{boxShadow:'none'}}}}
        onChange={(c)=>setColor(fromRGBColor(c.rgb))} />
      </Popover>
    </Box>
  );

}