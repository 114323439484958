import { Grid, Typography, ToggleButton, Switch } from "@mui/material";
import { FormatBold } from "@mui/icons-material"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FontList, FontWeights } from "../fontList";
import { ArraySelector } from "./ArraySelector";
import { setDefaultFont, setFont, setWeight, toggleRandomWeights } from "./formSlice";

const getFonts = (exDefault = false) => exDefault ? Object.entries(FontList).map((f)=>f[1]).filter((f)=>f!==FontList.DEFAULT) : Object.entries(FontList).map((f)=>f[1]);

export const FontSelector = ({number}:{number:number}) => {
  const font = useAppSelector((state)=>state.form.statements[number].font);
  const dispatch = useAppDispatch();
  
  const fonts = getFonts();
  
  return (
      <ArraySelector
        initial={fonts.findIndex((f)=>f===font)}
        array={fonts}
        item={(v)=><Typography sx={{fontFamily:`'${v}'`}}>{v}</Typography>}
        onChange={(_, i)=>{
          dispatch(setFont({number, font:fonts[i]}));
        }} />
    )
}

export const FontSettings = () => {
  const font = useAppSelector((state)=>state.form.font);
  const randomWeights = useAppSelector((state)=>state.form.randomWeights);

  const dispatch = useAppDispatch();

  const fonts = getFonts(true);
  
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item sx={{paddingX:1}}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
              <Typography variant='body1'>フォント: </Typography>
          </Grid>
          <Grid item sx={{paddingX:1}} >
            <ArraySelector
              initial={fonts.findIndex((f)=>f===font)}
              array={fonts}
              item={(v)=><Typography sx={{fontFamily:`'${v}'`}}>{v}</Typography>}
              onChange={(_, i)=>{
                dispatch(setDefaultFont(fonts[i]));
              }} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{paddingX:1}}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
              <Typography variant='body1'>文字の太さをランダムに決定する</Typography>
          </Grid>
          <Grid item >
            <Switch color="primary"
                checked={randomWeights}
                onClick={()=>dispatch(toggleRandomWeights())} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    );
}

export const BoldButton = ({number}:{number:number}) => {
  const font = useAppSelector((state)=>state.form.statements[number].font);
  const defaultFont = useAppSelector((state)=>state.form.font);

  const randomWeights = useAppSelector((state)=>state.form.randomWeights);
  const weight = useAppSelector((state)=>state.form.statements[number].weight);

  const weights = FontWeights.getAll(font===FontList.DEFAULT ? defaultFont : font);
  const dispatch = useAppDispatch();

  if(randomWeights || weights.length===1) return (<div></div>);

  return (
    <ToggleButton value="bold" size="small" 
      selected={weight!==400}
      onChange={()=>
        dispatch(setWeight({number, value:weight!==400 ? 400 : weights[1]}))
      }
    >
      <FormatBold fontSize='small' />
    </ToggleButton>
  );
}