import { memo } from "react";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';



// https://zenn.dev/mamezou/articles/4d0d7b79b639d5
const useTracking = (trackingId: string) => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      window.gtag('config', trackingId, { page_path: location.pathname });
    });

    return unlisten;
  }, [trackingId, listen]);
};



export const Gtag: React.FC = memo((props) => {
    useTracking('UA-150913455-1');
    useTracking('G-NC3H73EYC1');

    return (<div>
        {props.children}
    </div>);
});