import { Grid, Paper, Box, Fab, Button, Divider, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import  { useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { SettingsBoard } from './SettingsBoard';

import { Statement } from './Statement';
import { add, reset } from './formSlice';
import { SubmitButton } from './SubmitButton';

export const Form = () =>{
  const texts = useAppSelector((state)=>state.form.statements);
  const dispatch = useAppDispatch();
  const [isAdvanced, setIsAdvanced] = useState(false);

  return (
    <Box>
        <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
            <Grid item xs={12}>
                <Paper>
                    <Tabs centered value={isAdvanced?1:0} onChange={(_, v)=>{setIsAdvanced(v===1)}}>
                        <Tab label='Basic'/>
                        <Tab label='Advanced'/>
                    </Tabs>
                </Paper>
            </Grid>
            {isAdvanced? <SettingsBoard /> :null}
            {texts.map((t, i)=><Statement number={i} isAdvanced={isAdvanced}/>)}
        </Grid>
        <Box　display="flex" justifyContent='center' margin={2}>
            <Fab size="medium" color='primary' aria-label="add" onClick={()=>{dispatch(add())}}><AddIcon/></Fab>
        </Box>
        <ClearButton onClear={()=>dispatch(reset())}/>
        <Divider variant='middle'/>

        <SubmitButton isAdvanced={isAdvanced}/>
    </Box>
);
}

const ClearButton = ({onClear}:{onClear:()=>void}) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <Button color="primary" onClick={()=>setIsOpen(true)}>クリア</Button>
            <Dialog open={isOpen} onClose={()=>setIsOpen(false)}>
                <DialogTitle >発言をクリア</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                    入力された全ての発言を削除します。
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setIsOpen(false)} variant="contained" color="primary" autoFocus>
                        キャンセル
                    </Button>
                    <Button onClick={()=>{onClear();setIsOpen(false);}} style={{color:"red"}}>
                        削除
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

