import {　Grid, Paper, Typography, Divider, Chip, Box } from "@mui/material"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import { PageBase } from "./PageBase";

export const Usage = () => {

    return (
        <PageBase>
        <Grid container spacing={2} alignItems="center" direction='column'>

        <Grid item >
            <Paper sx={{padding:2, textAlign:'justify'}}>
                <Typography variant='h5'>使い方</Typography>
                <Divider/>
                <ul>
                    <li>画像に入れたい発言を入力欄に打ち込んでください。</li>
                    <li><AddCircleIcon color='primary' sx={{verticalAlign:'middle'}}/>を押すことで入力欄の数を増やすことができます。</li>
                    <li>入力が終わったら、「作成」を押して画像を生成してください。</li>
                    <li>画像が生成されます。レイアウトはランダムに決まり、「更新」を押すと文字の配置や大きさ、太さが変わります。</li>
                </ul>

                <Divider textAlign="left" sx={{paddingY:2}}>
                    <Chip label="詳細な設定を行う場合" />
                </Divider>
                <Typography variant='body2'>「ADVANCED」タブに切り替えることで、詳細な設定をすることができます。</Typography>
                <ul>
                    <li>「文字色」「背景色」「フォント」からデフォルトの設定を変更できます。</li>
                    <li>画像に挿入される「発言まとめメーカー」のマークの色は「文字色」に依存します。</li>
                    <li>「文字の太さをランダムに決定する」が選択されている場合は、描画される文字の太さがランダムに決まります。
                    選択されていない場合は、それぞれの発言について<FormatBoldIcon sx={{verticalAlign:'middle'}}/>を選択したものだけが太字で描画されます。</li>
                    <li>「縦横比」からは生成される画像の縦横比を設定することができ、「カスタム」を選択した場合は画像のサイズを自由に変更することが可能です。</li>
                    <li>「余白」では発言の周囲に設ける余白の大きさを変更できます。それぞれの発言の周りに指定した大きさの余白が設けられるため、発言同士の間隔は指定した数値の2倍になります。</li>
                </ul>
            </Paper>
        </Grid>

        <Grid item >
            <Paper sx={{padding:2, textAlign:'justify'}}>
                <Typography variant='h5'>画像の利用について</Typography>
                <Divider/>
                <Box sx={{paddingY:2}}>

                <Typography variant='body1'>
                    発言まとめメーカーで生成された画像は個人、法人、商用、非商用に関わりなく無料で利用することができます。
                    使用にあたっての報告等も不要です。
                    <br />
                    ただし、生成された画像およびその内容によって生じるトラブルや損害については一切の責任を負いかねますのでご了承ください。
                    <br />
                    画像の加工等については以下をご覧ください。
                </Typography>

                <Divider textAlign="left" sx={{paddingY:2}}>
                    <Chip label="非商用利用の場合" />
                </Divider>
                <Typography variant='body1'>
                    好きに使ってください。
                    <br />
                    画像の二次配布や編集・加工等も自由です。
                </Typography>

                <Divider textAlign="left" sx={{paddingY:2}}>
                    <Chip label="商用利用の場合" />
                </Divider>
                <Typography variant='body1'>
                    自由に編集・加工していただいて構いませんが、画像に挿入された「発言まとめメーカー」のマークは保持するようにしてください。
                    やむを得ずマークを取り除く場合は、当サイトの名称とリンクを容易に確認できる箇所に記載してください。
                    マークの色は「文字色」に依存しますが、背景色との兼ね合いでマークが判読しづらい場合も同様に、当サイトの名称とリンクを記載してください。
                    二次配布を行う場合も必ず当サイトのリンクを記載するようにしてください。
                </Typography>
                
                </Box>
            </Paper>
        </Grid>


        </Grid>
    </PageBase>
    );

}