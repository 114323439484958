export const sleep = (ms: number) =>
	new Promise<void>((resolve) => {
		setTimeout(() => {
			resolve();
		}, ms);
	});

export const toNum = (num: string) => {
	const number = Number(num);
	return isNaN(number) ? 0 : number;
};
