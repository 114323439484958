import WebFont from "webfontloader";
import { StatementInput } from "./renderer";

export const getRequiredFontConfigs = (statements: StatementInput[]) => {
	const requiredFonts = [
		...new Set(statements.map((s) => `${s.font}:${s.weight}`)),
	];
	const required = requiredFonts.map((font) => {
		const text = statements
			.filter((s) => `${s.font}:${s.weight}` === font)
			.map((s) => s.texts)
			.flat()
			.join("");
		return { font: font, text: [...new Set(text)].join("") };
	});

	const googleConfigs = required.map((r) => {
		return {
			google: {
				families: [r.font],
				text: r.text,
			},
		};
	});

	const requiredVerticalFonts = [
		...new Set(statements.map((s) => `${s.font} Vertical`)),
	];
	const customConfig = {
		custom: {
			families: requiredVerticalFonts,
			urls: [`${process.env.PUBLIC_URL}/fonts.css`],
		},
	};

	return [...googleConfigs, customConfig];
};

export const loadWebFonts = (configs: WebFont.Config[]) =>
	new Promise<void>((resolve, reject) => {
		let count = 0;
		const max = configs.reduce((sum, config) => {
			if (config.google) return sum++;
			if (config.custom) return sum + (config.custom.families?.length ?? 0);
			return sum;
		}, 0);
		configs.forEach((config) => {
			WebFont.load({
				...config,
				classes: false,
				fontactive: (font) => {
					count++;
					if (count === max) resolve();
				},
				fontinactive: (font) =>
					reject(new Error(`'${font}' couldn't be loaded or not supported.`)),
			});
		});
	});

export const loadFonts = async (statements: StatementInput[]) => {
	const requiredFonts = [
		...new Set(statements.map((s) => `${s.font}:${s.weight}`)),
	];
	const requiredGoogleFonts = requiredFonts.map((fw) => {
		const text = statements
			.filter((s) => `${s.font}:${s.weight}` === fw)
			.map((s) => s.texts)
			.flat()
			.join("");
		const temp = fw.split(":");
		return {
			font: temp[0],
			weight: temp[1],
			text: [...new Set(text)].join(""),
		};
	});

	const requiredVerticalFonts = [
		...new Set(statements.map((s) => `${s.font} Vertical:${s.weight}`)),
	].map((fw) => {
		const temp = fw.split(":");
		return { font: temp[0], weight: temp[1] };
	});

	const unloadedGoogleFonts = requiredGoogleFonts.filter((f) => !check(f));
	await Promise.all(unloadedGoogleFonts.map((f) => loadGoogleFont(f)));

	const unloadedVerticalFonts = requiredVerticalFonts.filter((f) => !check(f));
	await Promise.all(unloadedVerticalFonts.map((f) => loadVerticalFont(f)));

	return true;
};

const check = ({
	font,
	weight,
	text,
}: {
	font: string;
	weight: string;
	text?: string;
}) => document.fonts.check(`${weight} 12px '${font}'`, text);

const loadGoogleFont = async ({
	font,
	weight,
	text,
}: {
	font: string;
	weight: string;
	text: string;
}) => {
	const family = font.replace(" ", "+") + ":" + weight;
	const response = await fetch(
		`https://fonts.googleapis.com/css?family=${family}&text=${encodeURIComponent(
			text
		)}`
	);
	if (response.ok) {
		const css = await response.text();
		const match = css.match(/url\(.+?\)/g);

		if (!match) throw new Error(`Can't find '${font}':${weight}.`);
		for (const url of match) {
			const fontFace = new FontFace(font, url, { weight: weight });
			const f = await fontFace.load();
			document.fonts.add(f);
		}
		return true;
	}
	throw new Error(
		`Can't download '${font}':${weight}.\n${response.statusText}`
	);
};

const loadVerticalFont = async ({
	font,
	weight,
}: {
	font: string;
	weight: string;
}) => {
	const fontFaces = await document.fonts.load(`${weight} 12px '${font}'`);
	if (fontFaces.length === 0)
		throw new Error(`Can't load '${font}':${weight}.`);
	return true;
};
