import { canBeVertical, FontList, getVeriticalText } from "../fontList";
import {
	getRequiredFontConfigs,
	loadFonts,
	loadWebFonts,
} from "./loadWebFonts";
import { Box, BoxLayouter, Direction } from "./boxLayouter";
import { sleep } from "../../util";
import { Color } from "../color";

export interface StatementInput {
	texts: string[];
	font: FontList;
	weight: number;
	color: Color;
}

export interface ImageInput {
	statements: StatementInput[];
	backgroundColor: Color;
	defaultColor: Color;
	width: number;
	height: number;
	spacing: number;
	seed: number;
}

interface Callbacks {
	onFontLoadStart: () => void;
	onRenderingStart: () => void;
	onImageCreationStart: () => void;
}

export const createImage = async (input: ImageInput, callbacks?: Callbacks) => {
	if (input.width < 1 || input.height < 1) {
		throw new Error("Invalid image size.");
	}

	const canvas = document.createElement("canvas");
	canvas.width = input.width;
	canvas.height = input.height;
	const context = canvas.getContext("2d");

	if (context) {
		callbacks?.onFontLoadStart();
		if (window.FontFace) {
			await loadFonts(input.statements);
		} else {
			const configs = getRequiredFontConfigs(input.statements);
			await loadWebFonts(configs);
			await sleep(500);
		}

		callbacks?.onRenderingStart();
		const failures = await renderImage(context, input);
		callbacks?.onImageCreationStart();
		const objectUrl = await createImageURL(canvas);
		const dataUrl = canvas.toDataURL();
		return { objectUrl, dataUrl, message: createMessage(failures) };
	} else {
		throw new Error("CanvasRenderingContext2D is not available.");
	}
};

const createMessage = (failures: number[]) => {
	if (failures.length !== 0) {
		return `${failures.length}個の発言を配置できませんでした。\n
		画像サイズを大きくするか、発言の数を少なくしてください。`;
	}
	return "";
};

const renderImage = async (
	context: CanvasRenderingContext2D,
	input: ImageInput,
	padding = 10
) => {
	context.save();

	context.fillStyle = input.backgroundColor.hex;
	context.fillRect(0, 0, input.width, input.height);
	const logo = await renderLogo(context, input.defaultColor);

	context.translate(padding, padding);

	const [wScale, hScale] = [
		1 - (2 * padding) / input.width,
		1 - (2 * padding) / input.height,
	];
	context.scale(wScale, hScale);

	const layouter = new BoxLayouter({
		seed: input.seed,
		width: input.width,
		height: input.height,
		spacing: input.spacing,
	});
	setLogoBox(layouter, logo, wScale, hScale, padding, input.spacing);

	const statementBoxes = input.statements.map((statement) =>
		statementToBoxes(context, statement)
	);
	const layouts = layouter.layout(statementBoxes);

	const failures: number[] = [];
	layouts.forEach((layout, i) => {
		if (layout) {
			if (layout.direction === Direction.HORIZONTAL)
				renderHorizontalText(context, input.statements[i], layout.boxes);
			else renderVerticalText(context, input.statements[i], layout.boxes);
		} else {
			failures.push(i);
		}
	});
	context.restore();

	return failures;
};

const setLogoBox = (
	layouter: BoxLayouter,
	logo: { width: number; height: number },
	wScale: number,
	hScale: number,
	padding: number,
	spacing: number
) => {
	const [scaledLogoWidth, scaledLogoHeight] = [
		Math.ceil((logo.width - padding) / wScale),
		Math.ceil((logo.height - padding) / hScale),
	];
	layouter.setBox({
		x: layouter.width - scaledLogoWidth - spacing,
		y: layouter.height - scaledLogoHeight - spacing,
		width: scaledLogoWidth + spacing,
		height: scaledLogoHeight + spacing,
	});
};

const getFontFamily = (font: FontList, isVertical = false) => {
	const emojis =
		'"Apple Color Emoji", "Noto Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
	if (isVertical) return `"${font} Vertical", "${font}", ` + emojis;
	return `"${font}", ` + emojis;
};

const statementToBoxes = (
	context: CanvasRenderingContext2D,
	statement: StatementInput
) => {
	context.font = `${statement.weight} 1px ${getFontFamily(statement.font)}`;
	let hArea = 0;
	let vArea = 0;
	const horizontal = {
		boxes: statement.texts.map((text, i) => {
			const width = Math.ceil(context.measureText(text).width);
			hArea += width;
			return {
				x: 0,
				y: i,
				width,
				height: 1,
			};
		}),
		normalizedArea: hArea,
	};
	const isAvailable = canBeVertical(statement);
	const vertical = isAvailable
		? {
				boxes: statement.texts.map((text, i) => {
					vArea += text.length;
					return {
						x: -i,
						y: 0,
						width: -1,
						height: text.length,
					};
				}),
				normalizedArea: vArea,
		  }
		: undefined;
	return { horizontal, vertical };
};

const renderHorizontalText = (
	context: CanvasRenderingContext2D,
	statement: StatementInput,
	boxes: Box[]
) => {
	context.textAlign = "left";
	context.textBaseline = "middle";
	context.fillStyle = statement.color.hex;
	context.font = `${statement.weight} ${boxes[0].height}px ${getFontFamily(
		statement.font
	)}`;

	boxes.forEach((box, i) => {
		// context.fillRect(box.x, box.y, box.width, box.height);
		context.fillText(
			statement.texts[i],
			box.x,
			box.y + 0.5 * box.height,
			box.width
		);
	});
};

const renderVerticalText = (
	context: CanvasRenderingContext2D,
	statement: StatementInput,
	boxes: Box[]
) => {
	context.textAlign = "center";
	context.textBaseline = "middle";
	context.fillStyle = statement.color.hex;
	context.font = `${statement.weight} ${-boxes[0].width}px ${getFontFamily(
		statement.font,
		true
	)}`;

	statement.texts.forEach((t, i) => {
		// context.fillRect(boxes[i].x, boxes[i].y, boxes[i].width, boxes[i].height);
		const text = getVeriticalText(t, statement.font);
		[...text].forEach((char, j) => {
			context.fillText(
				char,
				boxes[i].x + boxes[i].width / 2,
				boxes[i].y - (j + 0.5) * boxes[i].width,
				-boxes[0].width
			);
		});
	});
};

const renderLogo = async (context: CanvasRenderingContext2D, color: Color) => {
	const logo = new Image();
	logo.src = `${process.env.PUBLIC_URL}/logo_black.png`;
	await logo.decode();

	const colorized = async (width: number, height: number) => {
		const canvas = document.createElement("canvas");
		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext("2d");
		if (!ctx) throw new Error("CanvasRenderingContext2D is not available.");
		ctx.fillStyle = color.hex;
		ctx.fillRect(0, 0, width, height);
		ctx.globalCompositeOperation = "destination-in";
		ctx.drawImage(logo, 0, 0);
		ctx.globalCompositeOperation = "source-over";
		const dataUrl = canvas.toDataURL();
		const img = new Image();
		img.src = dataUrl;
		await img.decode();
		return img;
	};

	if (
		context.canvas.width > logo.width &&
		context.canvas.height > logo.height
	) {
		const dx = context.canvas.width - logo.width;
		const dy = context.canvas.height - logo.height;
		context.drawImage(await colorized(logo.width, logo.height), dx, dy);
		return { width: logo.width, height: logo.height };
	} else {
		const scale = Math.min(
			context.canvas.width / logo.width,
			context.canvas.height / logo.height
		);
		const dx = context.canvas.width - scale * logo.width;
		const dy = context.canvas.height - scale * logo.height;
		const scaledW = scale * logo.width;
		const scaledH = scale * logo.height;
		context.drawImage(await colorized(logo.width, logo.height), dx, dy);
		return { width: scaledW, height: scaledH };
	}
};

const createImageURL = (canvas: HTMLCanvasElement) =>
	new Promise<string>((resolve, reject) => {
		try {
			canvas.toBlob((blob) => {
				if (blob) resolve(URL.createObjectURL(blob));
				else reject("Converting the image to blob failed.");
			});
		} catch (error) {
			reject(error);
		}
	});
