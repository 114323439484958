export class LayoutMap {
	private _width: number;
	private _height: number;

	private _emptyMap: boolean[][];
	private _cache?: boolean[][];

	private _emptyNum: number;

	constructor(width: number, height: number) {
		this._width = width;
		this._height = height;

		this._emptyMap = [];
		for (let i: number = 0; i < height; i++) {
			this._emptyMap.push(new Array(width).fill(true));
		}

		this._emptyNum = width * height;
	}

	get emptyNum() {
		return this._emptyNum;
	}
	get empties() {
		return this._emptyMap;
	}
	get width() {
		return this._width;
	}
	get height() {
		return this._height;
	}

	log = () => {
		console.log(`Empty Num: ${this._emptyNum}`);

		if (!this._cache) {
			this._cache = [];
			for (let i: number = 0; i < this._height; i++) {
				this._cache.push(new Array(this._width).fill(true));
			}
		}
		const log = this._emptyMap.reduce(
			(text, row, y) =>
				text +
				`${row.reduce((t, isEmpty, x) => {
					const char = isEmpty
						? "⬜"
						: this._cache && this._cache[y][x]
						? "🟥"
						: "⬛";
					return t + char;
				}, "")}\n`,
			""
		);
		console.log(log);
		console.log(`Empty:⬜ Changed:🟥 Fulfilled:⬛`);

		this._cache = this._emptyMap.map((row) => [...row]);
	};

	fulfill = (x: number, y: number, width: number, height: number) => {
		const left = Math.max(0, Math.min(x, x + width, this._width));
		const right = Math.min(this._width, Math.max(0, x, x + width));
		const top = Math.max(0, Math.min(y, y + height, this._height));
		const bottom = Math.min(this._height, Math.max(0, y, y + height));

		for (let dy = 0; dy < bottom - top; dy++) {
			const posY = top + dy;

			for (let dx = 0; dx < right - left; dx++) {
				const posX = left + dx;

				if (this._emptyMap[posY][posX]) {
					this._emptyMap[posY][posX] = false;
					this._emptyNum--;
				}
			}
		}
	};

	isEmpty = (x: number, y: number, width = 1, height = 1) => {
		const left = Math.min(x, x + width);
		const right = Math.max(x, x + width);
		const top = Math.min(y, y + height);
		const bottom = Math.max(y, y + height);

		if (left < 0 || this._width < right || top < 0 || this._height < bottom)
			return false;

		return this._emptyMap
			.slice(top, bottom)
			.every((row) => row.slice(left, right).every((v) => v));
	};

	getLocalDensity = (x: number, y: number, width = 10, height = 10) => {
		const left = Math.max(0, Math.min(x, x + width, this._width));
		const right = Math.min(this._width, Math.max(0, x, x + width));
		const top = Math.max(0, Math.min(y, y + height, this._height));
		const bottom = Math.min(this._height, Math.max(0, y, y + height));

		const max = (right - left) * (bottom - top);
		if (max === 0) return 1;
		const rows = this._emptyMap
			.slice(top, bottom)
			.map((row) => row.slice(left, right));
		const num = rows.reduce((sum, row) => sum + row.filter(Boolean).length, 0);
		return 1 - num / max;
	};

	getDensity = () => this.getLocalDensity(0, 0, this._width, this._height);
}
