const UPDATED_AT = "updatedAt";
const KEY = "state_v2";

// https://developer.mozilla.org/ja/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
export const localStorageAvailable = () => {
	try {
		const storage = window.localStorage;
		const x = "__storage_test__";
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return false;
	}
};

const isAvailable = localStorageAvailable();

let updatedAt = Date.now();
export const saveState = <T>(state: T, cycle = 500, key = KEY) => {
	if (isAvailable) {
		const now = Date.now();
		if (isNaN(updatedAt) || now - updatedAt > cycle) {
			localStorage.setItem(UPDATED_AT, now.toString());
			localStorage.setItem(key, JSON.stringify(state));
			updatedAt = now;
		}
	}
};

export const loadState = <T>(
	initialState: T,
	lifetime = 24 * 60 * 60 * 1000,
	key = KEY
) => {
	try {
		const updatedAt = Number(localStorage.getItem(UPDATED_AT));
		const value = localStorage.getItem(key);
		if (!isNaN(updatedAt) && Date.now() - updatedAt < lifetime && value) {
			return JSON.parse(value) as T;
		}
	} catch (e) {
		return initialState;
	}
	return initialState;
};
